<template>
    <div>
        <div class="grid" v-html="blogItemsMarkup.join('')"></div>
        <div v-inview:enter="update"></div>
        <div class="spinner" v-if="isUpdating"></div>
    </div>
</template>

<script>
import axios from 'axios';

let controller;

export default {
    props: {
        categories: {
            type: Array,
            default: () => []
        }
    },

    data () {
        return {
            blogItemsMarkup: ['<div class="grid-sizer"></div>'],
            fullyLoaded: false,
            isUpdating: false,
            page: 1
        };
    },

    methods: {
        update () {
            if (this.fullyLoaded) {
                return;
            }

            if (this.isUpdating) {
                this.page--;
                controller.abort(
                    'Aborting previous request due to filter change.'
                );
            }

            this.page++;
            this.isUpdating = true;

            let apiUrl = '/api/blog-entries';

            const params = new URLSearchParams();
            params.append('page', this.page);

            if (this.categories.length) {
                params.append('id', this.categories.join(','));
            }


            apiUrl = `${apiUrl}?${params}`;

            controller = new AbortController();

            axios.get(apiUrl, { signal: controller.signal })
                .then(response => {
                    this.isUpdating = false;
                    if (response.data.length === 0) {
                        this.fullyLoaded = true;
                    } else {
                        // this.blogItemsMarkup.push(response.data);
                        this.$nextTick(() => {
                            let $items = $(response.data);
                            $('.grid').append($items).masonry('appended', $items);

                            let $images = $items.find('img');
                            $images.on("load", (e) => {
                                $('.grid').masonry()
                            })
                        })
                    }
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                    }
                });
        }
    },

    created () {
        this.blogItemsMarkup.push(document.querySelector('.grid').innerHTML);
    },

    mounted () {
        $('.grid').masonry({
            initLayout: false,
            itemSelector: '.grid-item',
            columnWidth: '.grid-item',
            percentPosition: true
        });

        let $images = $('.grid').find('img');
        $images.on("load", (e) => {
            $('.grid').masonry()
        });

        setTimeout(() => {
            $('.grid').masonry()
        }, 1000)
    }
}
</script>

<style scoped>
.spinner {
    margin-top: 0;
    margin-bottom: 140px;
}
</style>

