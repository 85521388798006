<template>
    <div class="row" id="portfolioChart">
        <div class="mb-4 col-md-4 mb-md-0 text-center">
            <div class="bg-white">
                <div class="title">{{ translate('portfolio') }}</div>
                <div class="data">
                    <span><i>{{sumCount}}</i><br>{{ translate('projects') }}</span>
                    <span><i>{{sumDsIntrests}}%</i><br>{{ translate('return') }}</span>
                </div>
                <div class="donutChart">
                    <div v-for="item in parts" :key="item.name" >
                        <div v-if="item.dag <= 180 && item.dag > 0" class="portionBlock" :style="{ transform: 'rotate('+item.start+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate('+item.dag+'deg)', background: '#'+item.color }"></div>
                        </div>
                        <div v-if="item.dag > 180" class="portionBlock" :style="{ transform: 'rotate('+item.start+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate(180deg)', background: '#'+item.color }"></div>
                        </div>
                        <div v-if="item.dag > 180" class="portionBlock" :style="{ transform: 'rotate('+(item.start+180)+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate('+(item.dag-180)+'deg)', background: '#'+item.color }"></div>
                        </div>
                    </div>
                    <div class="center"></div>
                    <div class="innerShadow"></div>
                    <div class="outerShadow"></div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="bg-white">
                <div class="title">{{ translate('trade_balance') }}</div>
                <table class="d-none d-sm-table">
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>{{ translate('your_investment_is') }}</td>
                            <td>{{ translate('invested') }}</td>
                            <td>&Oslash; {{ translate('investment') }}</td>
                            <td>{{ translate('revenue') }}</td>
                            <td>&Oslash; {{ translate('duration') }}</td>
                            <td>&Oslash; {{ translate('return_per_year') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in parts" :key="item.name">
                            <td><div class="color" :style="{ background: '#'+item.color }" :title="translate(item.description)"></div></td>
                            <td>{{ translate(item.name) }}</td>
                            <td>{{item.count}}</td>
                            <td>{{ item.invested | money }}</td>
                            <td>{{ item.dsInvested | money }}</td>
                            <td>{{item.profit | money}}</td>
                            <td v-if="item.dsDuration != '0,00' && item.name != 'ausgefallen'">{{item.dsDuration}}</td><td v-else></td>
                            <td v-if="item.dsIntrests != '0,00' && !['in Betreibung', 'ausgefallen'].includes(item.name)">{{item.dsIntrests}}%</td><td v-else></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{{ translate('actions') }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{{this.actionsValue | money}}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="sum">
                            <td></td>
                            <td>{{ translate('total') }}</td>
                            <td>{{sumCount}}</td>
                            <td>{{sumInvested}}</td>
                            <td>{{sumDsInvested}}</td>
                            <td>{{sumProfit}}</td>
                            <td>{{sumDsDuration}}</td>
                            <td>{{sumDsIntrests}}%</td>
                        </tr>
                        <tr v-if="sumRealisiert > 0">
                            <td></td>
                            <td>{{ translate('realized') }}</td>
                            <td>{{sumRealisiert}}</td>
                            <td>{{sumInvestedRealisiert}}</td>
                            <td>{{sumDsInvestedRealisiert}}</td>
                            <td>{{sumProfitRealisiert}}</td>
                            <td>{{sumDsDurationRealisiert}}</td>
                            <td>{{sumDsIntrestsRealisiert}}%</td>
                        </tr>
                        <tr v-if="sumInvestiert > 0">
                            <td></td>
                            <td>{{ translate('invested_lowercase') }}</td>
                            <td>{{sumInvestiert}}</td>
                            <td>{{sumInvestedInvestiert}}</td>
                            <td>{{sumDsInvestedInvestiert}}</td>
                            <td>{{sumProfitInvestiert}}</td>
                            <td>{{sumDsDurationInvestiert}}</td>
                            <td>{{sumDsIntrestsInvestiert}}%</td>
                        </tr>
                        <tr v-if="this.unpaid[0].count > 0">
                            <td></td>
                            <td>{{ translate('unpaid') }}</td>
                            <td>{{this.unpaid[0].count}}</td>
                            <td>{{this.unpaid[0].value}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr v-if="this.unpaid[1].count > 0">
                            <td></td>
                            <td>{{ translate('reserved') }}</td>
                            <td>{{this.unpaid[1].count}}</td>
                            <td>{{this.unpaid[1].value}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <table class="d-sm-none mobileTable" v-for="item in parts" :key="item.name">
                    <tr>
                        <td width="1px"><div class="color" :style="{ background: '#'+item.color }"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate(item.name) }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{item.count}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{item.invested | money}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('investment') }}</b><br>
                            {{item.dsInvested | money}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{item.profit | money}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            <span v-if="item.dsDuration != '0,00' && item.name != 'ausgefallen'">{{item.dsDuration}}</span>
                        </td>
                        <td>
                            <b>&Oslash; Rendite p.a.</b><br>
                            <span v-if="item.dsIntrests != '0,00' && !['in Betreibung', 'ausgefallen'].includes(item.name)">{{item.dsIntrests}}%</span>
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('actions') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{this.actionsValue | money}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            &nbsp;
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('total') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{sumCount}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{sumInvested}}
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            {{sumDsInvested}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{sumProfit}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            {{sumDsDuration}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            {{sumDsIntrests}}%
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable" v-if="sumRealisiert > 0">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('realized') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{sumRealisiert}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{sumInvestedRealisiert}}
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            {{sumDsInvestedRealisiert}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{sumProfitRealisiert}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            {{sumDsDurationRealisiert}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            {{sumDsIntrestsRealisiert}}%
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable" v-if="sumInvestiert > 0">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('invested_lowercase') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{sumInvestiert}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{sumInvestedInvestiert}}
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            {{sumDsInvestedInvestiert}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{sumProfitInvestiert}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            {{sumDsDurationInvestiert}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            {{sumDsIntrestsInvestiert}}%
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable" v-if="this.unpaid[0].count > 0">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td class="text-left"><h5>{{ translate('unpaid') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{this.unpaid[0].count}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{this.unpaid[0].value}}
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable" v-if="this.unpaid[1].count > 0">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td class="text-left"><h5>{{ translate('reserved') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{this.unpaid[1].count}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{this.unpaid[1].value}}
                        </td>
                    </tr>
                </table>
                <div id="rangeSelector">
                    <div class="row mb-2 mt-5">
                        <div class="col-12">
                            {{ translate('investment_amount_for_simulation') }}
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-6 col-lg-4">
                            <input type="text" ref="nice" class="rangeCustomInput" v-model="inputValue" @focus="onFocus" @blur="onBlur" @input="onInput" />
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12">
                            <input class="customRangeSelector" type="range" id="selector" :min="min" :max="max" v-model="selectedValue" @input="onChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            values: Array,
            investments: Array,
            unpaid: Array,
            gap: {
                type: String,
                default: "0"
            },
            actions: {
                type: String,
                default: "0"
            },
            min: {
                type: String,
                default: "250"
            },
            max: {
                type: String,
                default: "25000"
            },
            start: {
                type: String,
                default: "500"
            },
        },

        computed: {
            sumCount() {
                let sum = 0;
                for(let i = 0; i < this.parts.length; i++) {
                    if(this.parts[i].count != "") {
                        sum = sum + this.parts[i].count;
                    }
                }

                return sum;
            },

            sumInvested() {
                let sum = 0;
                for(let i = 0; i < this.parts.length; i++) {
                    if(this.parts[i].invested != "") {
                        sum = sum + this.parts[i].invested;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsInvested() {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.selectedValue));
            },

            sumProfit() {
                let sum = 0;
                for(let i = 0; i < this.parts.length; i++) {
                    if(this.parts[i].profit != "") {
                        sum = sum + this.parts[i].profit;
                    }
                }
                sum = sum + this.actionsValue;
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsDuration() {
                let sum = 0;
                let sumInvested = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].dsDuration != "") {
                        sum = sum + (this.values[i].invested * this.values[i].dsDuration);
                    }
                    if(this.values[i].invested != "") {
                        sumInvested = sumInvested + this.values[i].invested;
                    }
                }
                sum = sum / sumInvested;
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumDsIntrests() {
                let value = 0;
                let sum = 0;
                let sumProfit = 0;
                let sumInvested = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].dsDuration != "") {
                        sum = sum + (this.values[i].invested * this.values[i].dsDuration);
                    }
                    if(this.values[i].profit != "") {
                        sumProfit = sumProfit + this.values[i].profit;
                    }
                    if(this.values[i].invested != "") {
                        sumInvested = sumInvested + this.values[i].invested;
                    }
                }
                sum = sum / sumInvested;

                sumProfit = sumProfit + parseInt(this.actions);

                value = sumProfit / sum / sumInvested * 12
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((value*100));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumRealisiert() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].count != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sum = sum + this.values[i].count;
                    }
                }

                return sum;
            },

            sumInvestedRealisiert() {
                let sum = 0;
                for(let i = 0; i < this.parts.length; i++) {
                    if(this.parts[i].invested != "" && (this.parts[i].name == 'zurückbezahlt' || this.parts[i].name == 'ausgefallen')) {
                        sum = sum + this.parts[i].invested;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsInvestedRealisiert() {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.selectedValue));
            },

            sumProfitRealisiert() {
                let sum = 0;
                for(let i = 0; i < this.parts.length; i++) {
                    if(this.parts[i].profit != "" && (this.parts[i].name == 'zurückbezahlt' || this.parts[i].name == 'ausgefallen')) {
                        sum = sum + this.parts[i].profit;
                    }
                }

                sum = sum + this.actionsValue;

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsDurationRealisiert() {
                let sum = 0;
                let sumInvested = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].dsDuration != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sum = sum + (this.values[i].invested * this.values[i].dsDuration);
                    }
                    if(this.values[i].invested != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sumInvested = sumInvested + this.values[i].invested;
                    }
                }
                sum = sum / sumInvested;
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumDsIntrestsRealisiert() {
                let value = 0;
                let sum = 0;
                let sumProfit = 0;
                let sumInvested = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].dsDuration != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sum = sum + (this.values[i].invested * this.values[i].dsDuration);
                    }
                    if(this.values[i].profit != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sumProfit = sumProfit + this.values[i].profit;
                    }
                    if(this.values[i].invested != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sumInvested = sumInvested + this.values[i].invested;
                    }
                }
                sum = sum / sumInvested;

                sumProfit = sumProfit + parseInt(this.actions);

                value = sumProfit / sum / sumInvested * 12;

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((value*100));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumInvestiert() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].count != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sum = sum + this.values[i].count;
                    }
                }

                return sum;
            },

            sumInvestedInvestiert() {
                let sum = 0;
                for(let i = 0; i < this.parts.length; i++) {
                    if(this.parts[i].invested != "" && (this.parts[i].name == 'im Verdienen' || this.parts[i].name == 'verlängert' || this.parts[i].name == 'verspätet' || this.parts[i].name == 'in Betreibung')) {
                        sum = sum + this.parts[i].invested;
                    }
                }
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsInvestedInvestiert() {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.selectedValue));
            },

            sumProfitInvestiert() {
                let sum = 0;
                for(let i = 0; i < this.parts.length; i++) {
                    if(this.parts[i].profit != "" && (this.parts[i].name == 'im Verdienen' || this.parts[i].name == 'verlängert' || this.parts[i].name == 'verspätet' || this.parts[i].name == 'in Betreibung')) {
                        sum = sum + this.parts[i].profit;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsDurationInvestiert() {
                let sum = 0;
                let sumInvested = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].dsDuration != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sum = sum + (this.values[i].invested * this.values[i].dsDuration);
                    }
                    if(this.values[i].invested != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sumInvested = sumInvested + this.values[i].invested;
                    }
                }
                sum = sum / sumInvested;
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumDsIntrestsInvestiert() {
                let value = 0;
                let sum = 0;
                let sumProfit = 0;
                let sumInvested = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].dsDuration != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sum = sum + (this.values[i].invested * this.values[i].dsDuration);
                    }
                    if(this.values[i].profit != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sumProfit = sumProfit + this.values[i].profit;
                    }
                    if(this.values[i].invested != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sumInvested = sumInvested + this.values[i].invested;
                    }
                }
                sum = sum / sumInvested;

                value = sumProfit / sum / sumInvested * 12
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((value*100));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },
        },

        created() {
            this.inputValue = "EUR " + this.selectedValue;
            this.invoices = this.investments;

            let start = 0;
            let sumValueStart = 0;
            for(let i = 0; i < this.values.length; i++) {
                sumValueStart = sumValueStart + this.values[i].count;
            }
            for(let i = 0; i < this.values.length; i++) {
                let tmp = {
                    name: this.values[i].name,
                    count: this.values[i].count,
                    description: this.values[i].description,
                    invested: this.values[i].invested,
                    dsInvested: this.values[i].count > 0 ? this.values[i].invested / this.values[i].count : 0,
                    profit: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].profit)),
                    dsDuration: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].dsDuration)),
                    dsIntrests: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].dsIntrests)),
                    value: this.values[i].value,
                    color: this.values[i].color,
                    start: start,
                    dag: 360 * (parseInt(this.values[i].count) / sumValueStart) - parseInt(this.gap)
                };
                this.parts.push(tmp);
                start = start + 360 * (parseInt(this.values[i].count) / sumValueStart);
            }

            this.actionsValue = parseFloat(this.actions);

            this.changeInvestment();
        },

        methods: {
            onFocus () {
                this.inputValue = this.selectedValue;
            },

            onBlur () {
                if(this.inputValue < this.min) {
                    this.inputValue = this.min;
                }
                this.selectedValue = this.inputValue
                this.inputValue = "EUR " + this.inputValue;
            },

            onInput () {
                this.inputValue = Number(this.inputValue);
                if(Number.isNaN(this.inputValue)) {
                    this.inputValue = this.min;
                }
                this.selectedValue = this.inputValue;
                this.changeInvestment();
            },

            onChange() {
                this.inputValue = "EUR " + this.selectedValue;
                this.changeInvestment();
            },

            changeInvestment() {
                this.parts[0].invested = this.selectedValue * this.parts[0].count;
                this.parts[0].dsInvested = this.selectedValue;
                this.parts[0].profit = (this.values[0].profit / 500) * this.selectedValue;
                this.parts[1].invested = this.selectedValue * this.parts[1].count;
                this.parts[1].dsInvested = this.selectedValue;
                this.parts[1].profit = (this.values[1].profit / 500) * this.selectedValue;
                this.parts[2].invested = this.selectedValue * this.parts[2].count;
                this.parts[2].dsInvested = this.selectedValue;
                this.parts[2].profit = (this.values[2].profit / 500) * this.selectedValue;
                this.parts[3].invested = this.selectedValue * this.parts[3].count;
                this.parts[3].dsInvested = this.selectedValue;
                this.parts[3].profit = (this.values[3].profit / 500) * this.selectedValue;
                this.parts[4].invested = this.selectedValue * this.parts[4].count;
                this.parts[4].dsInvested = this.selectedValue;
                this.parts[4].profit = (this.values[4].profit / 500) * this.selectedValue;

                this.actionsValue = (this.actions / 500) * this.selectedValue;
            }
        },

        filters: {
            money(value) {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((value));
            }
        },

        data() {
            return {
                parts: [],
                filters: [],
                actionsValue: 0,
                selectedValue: this.start,
                inputValue: null,
                invoices: []
            };
        }
    }
</script>
