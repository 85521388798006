import { render, staticRenderFns } from "./ClosedProjectsList.vue?vue&type=template&id=09f7b091&scoped=true"
import script from "./ClosedProjectsList.vue?vue&type=script&lang=js"
export * from "./ClosedProjectsList.vue?vue&type=script&lang=js"
import style0 from "./ClosedProjectsList.vue?vue&type=style&index=0&id=09f7b091&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f7b091",
  null
  
)

export default component.exports