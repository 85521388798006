<template>
    <div class="row justify-content-center">
        <div class="col-12 col-md-5">
            <div class="input-group spinnerInvestment" v-if="lockAmount">
                <input type="text" name="investment" id="investmentAmount" class="form-control" v-model="inputValue" @focus="onFocus" @blur="onBlur" @input="onInput" readonly />
                <button class="btn btn-secondary small ml-3" @click="enableamount">{{ translate('change') }}</button>
            </div>
            <div class="input-group spinnerInvestment" v-else>
                <input type="text" name="investment" id="investmentAmount" class="form-control" v-model="inputValue" @focus="onFocus" @blur="onBlur" @input="onInput" />
                <div class="input-group-append" @click="increase">
                    <i class="icon-plus"></i>
                </div>
                <div class="input-group-append" @click="decrease">
                    <i class="icon-minus"></i>
                </div>
            </div>
            <div class="text-left highInvestmentCheckContainer" :class="{ show: lowInvestment, 'mt-3': lowInvestment }">
                <span class="text-green">Der gewählte Investmentbetrag beträgt maximal 10% Ihres Reinvermögens. Im Falle eines Totalverlustes verbleibt Ihnen ein Reinvermögen in Höhe von EUR {{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.max*10 - this.selectedValue)}}.</span>
            </div>
            <div class="text-left highInvestmentCheckContainer" :class="{ show: normalInvestment, 'mt-3': normalInvestment }">
                Der gewählte Investmentbetrag beträgt mehr als 10% Ihres Reinvermögens. Im Falle eines Totalverlustes verbleibt Ihnen ein Reinvermögen in Höhe von {{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.max*10 - this.selectedValue)}}. Wir empfehlen das Risiko eines Totalverlustes durch Diversifikation zu verringern.
            </div>
            <div class="text-left highInvestmentCheckContainer" :class="{ show: highInvestment, 'mt-3': highInvestment }">
                <span class="text-red">Der gewählte Investmentbetrag beträgt mehr als das von Ihnen angegebene Reinvermögen. Diesen Angaben zufolge können Sie das Risiko eines Totalverlusts nicht tragen.</span>
            </div>
            <div class="text-left highInvestmentCheckContainer" :class="{ show: investment25k, 'mt-3': investment25k }">
                <span class="text-red">
                    Zum Schutz sogenannter "potentiell nicht kundiger Anleger" ist eine Investition über EUR 25.000,- aktuell nicht möglich.<br>
                    Sie können <a href="/backend/profildaten/experienceData" target="_blank">hier</a> einen Antrag auf Behandlung als kundiger Anleger stellen. Für kundige Anleger gelten keine Obergrenzen bei Investitionen in einzelne Projekte.
                </span>
            </div>
            <div class="text-left highInvestmentCheckContainer" :class="{ show: investmentBlock, 'mt-3': investmentBlock }">
                <span class="text-red">
                    Aufgrund Ihrer Angaben zu <a href="/backend/profildaten/experienceData" target="_blank">Kenntnissen und Erfahrungen</a> mit Finanzanlagen ist eine Investition nur bis zu einem Maximalbetrag von EUR 1.000,- pro Projekt möglich. Sollten Sie einen größeren Betrag investieren wollen, bitten wir Sie, sich unsere Investitionsleitfaden aufmerksam durchzulesen und Ihre Angaben zu Kenntnissen und Erfahrungen noch einmal  gewissenhaft zu prüfen. Alternativ können Sie auch einen <a href="/backend/profildaten/experienceData/knowledgeableInvestor" target="_blank">Antrag auf Behandlung als kundiger Anleger</a> stellen.
                </span>
            </div>
            <div class="mt-3">
                <strong v-if="wealth">Ihr Reinvermögen beträgt EUR {{ new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(wealth) }}.</strong>
                <strong v-else>Sie haben sich entschieden, keine Angaben zu machen. Wir bitten Sie in Ihrem Sinne dennoch, diese Angaben unter <a href="/backend/profildaten/lossBearingCapacity" target="_blank">"Profildaten / Simulation möglicher Verluste"</a> nachzuholen.</strong>
            </div>
            <div class="mb-3">
                <div v-if="lockCode">
                    <input type="text" name="actionCode" class="form-control actionCode" placeholder="Gutschein- oder Aktionscode" v-model="actionCode"  @blur="checkCode" readonly />
                    <button class="btn btn-secondary small" @click="enablecode">ändern</button>
                </div>
                <input type="text" name="actionCode" class="form-control actionCode" placeholder="Gutschein- oder Aktionscode" v-model="actionCode"  @blur="checkCode" v-else />
                <span v-if="erroractioncode != ''" class="invalid-feedback" role="alert">
                    <strong>{{ erroractioncode }}</strong>
                </span>
            </div>
        </div>
        <div class="col-12 col-md-5">
            <div class="hint show" v-if="!isPlatinumProduct">
                <b>Hinweis:</b> Aufgrund der Teuerungen in vielen Bereichen haben wir beschlossen, die Mindestinvestitionssumme bis auf Weiteres von <span class="ntl-monetary">EUR 250</span> auf <span class="ntl-monetary">EUR {{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.min)}}</span> (in <span class="ntl-monetary">EUR {{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.step)}}</span> Schritten) zu senken, um unserer Crowd weiterhin die Möglichkeit zu geben ein breitgestreutes Portfolio aufzubauen.
            </div>
            <div class="hint" :class="{ error: showHint3 }">
                {{ translate('info_personal_maximum_investment', [{ key: 'MAXIMUM_INVESTMENT', value: maxinvest }]) }}
            </div>
            <div class="hint" :class="{ show: showHint4 }">
                <span v-html="translate('info_action_code', [{ key: 'MINIMUM_INVESTMENT', value: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.min) }])"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import Xhr from '../core/xhr';

    export default {
        props: {
            productid: {
                type: String
            },
            maxinvest: {
                type: String,
                default: "0"
            },
            invested: {
                type: String,
                default: "0"
            },
            min: {
                type: String,
                default: "100"
            },
            max: {
                type: String,
                default: "5000"
            },
            wealth: {
                type: String
            },
            step: {
                type: String,
                default: "100"
            },
            duration: {
                type: Number,
                default: 12
            },
            serviceFeePercentage: {
                type: Number
            },
            isPlatinumProduct: {
                type: Boolean,
                default: false
            },
            start: String,
            erroractioncode: {
                type: String,
                default: ''
            },
            oldactioncode: {
                type: String,
                default: ''
            },
            lockamount: {
                type: Boolean,
                default: false
            },
            lockcode: {
                type: Boolean,
                default: false
            },
            isanlageform: {
                type: Boolean,
                default: false
            },
            isunerfahren: {
                type: Boolean,
                default: false
            },
            isknowledgeableinvestor: {
                type: Boolean,
                default: false
            },
            transactionid: {
                type: String,
                default: null
            },
        },

        filters: {
            numberFormat (value) {
                return "EUR " + value;
            },

            money (value) {
                return new Intl.NumberFormat(
                    'de-DE',
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                ).format(value)
            }
        },

        created() {
            this.selectedValue = Number(this.start) > Number(this.maxinvest) ? this.maxinvest : this.start;
            this.inputValue = "EUR " + this.selectedValue;
            this.actionCode = this.oldactioncode;
            this.oldMinValue = this.min;
            this.extraCheckBeforeInvesting();
            this.blockInvestment();
        },

        methods: {
            enableamount() {
                this.lockAmount = false;
                document.getElementById('riskCheck').checked = false;
                document.getElementById('investmentCheck').checked = false;
            },

            enablecode() {
                this.lockCode = false;
            },

            onFocus () {
                this.inputValue = this.selectedValue;
            },

            onBlur () {
                if(this.inputValue < this.min) {
                    this.inputValue = this.min;
                    this.showHint = true;
                }

                if(this.inputValue % this.step)
                {
                    this.inputValue = Math.round(this.inputValue/this.step) * this.step;
                    this.showHint = true;
                }

                window.App.dispatcher.fire(
                    'investmentValueChange',
                    { investmentValue: this.inputValue, zeroBondDiscount: 0 }
                )

                this.selectedValue = this.inputValue
                this.inputValue = "EUR " + this.inputValue;

                window.App.dispatcher.fire(
                    'serviceFeeChange',
                    { serviceFee: this.serviceFee }
                )
            },

            onInput () {
                this.inputValue = Number(this.inputValue);
                if(Number.isNaN(this.inputValue)) {
                    this.inputValue = this.min;
                }
                this.selectedValue = this.inputValue

                if(this.selectedValue > this.maxinvest) {
                    this.showHint3 = true;
                    this.inputValue = this.maxinvest;
                }

                window.App.dispatcher.fire(
                    'investmentValueChange',
                    { investmentValue: this.inputValue }
                )

                this.checkInvestmentWarning();
                this.extraCheckBeforeInvesting();
                this.blockInvestment();

                window.App.dispatcher.fire(
                    'serviceFeeChange',
                    { serviceFee: this.serviceFee }
                )
            },

            increase() {
                this.selectedValue = Number(this.selectedValue) + Number(this.step);
                if(this.selectedValue > this.maxinvest) {
                    this.showHint3 = true;
                    this.selectedValue = this.maxinvest;
                }
                this.inputValue = "EUR " + this.selectedValue;

                this.checkInvestmentWarning();
                this.extraCheckBeforeInvesting();
                this.blockInvestment();
            },

            decrease() {
                if((Number(this.selectedValue) - Number(this.step)) >= this.min) {
                    this.selectedValue = Number(this.selectedValue) - Number(this.step);
                    this.inputValue = "EUR " + this.selectedValue;
                }
                this.checkInvestmentWarning();
                this.extraCheckBeforeInvesting();
                this.blockInvestment();
            },

            checkInvestmentWarning() {
                this.lowInvestment = false;
                this.normalInvestment = false;
                this.highInvestment = false;
                this.investmentBlock = false;

                if(this.max > 0) {
                    if(this.selectedValue <= (this.max - Number(this.invested))) {
                        this.lowInvestment = true;
                        this.normalInvestment = false;
                        this.highInvestment = false;
                    }

                    if(this.selectedValue > (this.max - Number(this.invested)) && this.selectedValue <= (this.max*10 - Number(this.invested))) {
                        this.lowInvestment = false;
                        this.normalInvestment = true;
                        this.highInvestment = false;
                    }

                    if(this.selectedValue > (this.max*10 - Number(this.invested))) {
                        this.lowInvestment = false;
                        this.normalInvestment = false;
                        this.highInvestment = true;
                    }
                }
            },

            checkCode () {
                new Xhr({  productId: this.productid, actionCode: this.actionCode, investment: this.selectedValue, transactionid: this.transactionid}).post('/invest/checkActionCode').then((response) => {
                    this.erroractioncode = "";
                    if($('#'+this.actionCode).length) {
                        $('#'+this.actionCode).collapse('show');
                    } else {
                        $('.specialFile').collapse('hide');
                    }

                    if(response.min_value !== null) {
                        this.min = response.min_value;
                        this.showHint4 = true;
                        this.showHint = false;
                    }
                }).catch (error => {
                    this.erroractioncode = error.errors.actionCode[0];
                    $('.specialFile').collapse('hide');
                    this.min = Number(this.oldMinValue);
                    this.showHint4 = false;
                    if(this.min > Number(this.selectedValue)) {
                        this.selectedValue = Number(this.min);
                        this.inputValue = "EUR " + this.selectedValue;
                        this.showHint = true;
                    }
                });

                if(this.actionCode == '') {
                    this.min = Number(this.oldMinValue);
                    this.showHint4 = false;
                    if(this.min > Number(this.selectedValue)) {
                        this.selectedValue = Number(this.min);
                        this.inputValue = "EUR " + this.selectedValue;
                        this.showHint = true;
                    }
                }
            },

            extraCheckBeforeInvesting() {
                if(this.selectedValue > (this.max / 2) && this.selectedValue > 1000) {
                    document.getElementById('riskMustBeChecked').value = 1;
                    document.getElementById('riskCheckBoxBlock').classList.remove('d-none');
                } else {
                    document.getElementById('riskMustBeChecked').value = 0;
                    document.getElementById('riskCheckBoxBlock').classList.add('d-none');
                }
            },

            blockInvestment() {
                this.investmentAbove25k();

                if((this.selectedValue > (this.max / 2) && this.selectedValue > 1000) && (this.isanlageform || this.isunerfahren)) {
                    this.investmentBlock = true;
                    this.lowInvestment = false;
                    this.normalInvestment = false;
                    this.highInvestment = false;
                } else {
                    this.investmentBlock = false;
                    this.checkInvestmentWarning();
                }

                if(this.investmentBlock == true || this.investment25k == true) {
                    document.getElementById('btnContainer').classList.add('d-none');
                } else {
                    document.getElementById('btnContainer').classList.remove('d-none');
                }
            },

            investmentAbove25k() {
                if(!this.isknowledgeableinvestor) {
                    if(Number(this.selectedValue) + Number(this.invested) > 25000) {
                        this.investment25k = true;
                    } else {
                        this.investment25k = false;
                    }
                }
            }
        },


        data () {
            return {
                selectedValue: null,
                inputValue: null,
                actionCode: null,
                showHint: false,
                showHint3: false,
                showHint4: false,
                lowInvestment: this.start <= (this.max - Number(this.invested)) && this.max > 0 ? true : false,
                normalInvestment: this.start > (this.max - Number(this.invested)) && this.start <= (this.max*10 - Number(this.invested)) && this.max > 0 ? true : false,
                highInvestment: this.start > (this.max*10 - Number(this.invested)) && this.max > 0 ? true : false,
                investment25k: false,
                investmentBlock: false,
                lockAmount: this.lockamount,
                lockCode: this.lockcode,
                oldMinValue: 0
            }
        },

        computed: {
            serviceFee () {
                const investmentValue = this.selectedValue
                    ? parseInt(this.selectedValue)
                    : 0

                return investmentValue * (this.serviceFeePercentage / 100) / 12 * this.duration
            }
        }
    }
</script>
