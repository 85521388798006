<template>
    <div class="table-responsive containerHeight">
        <table class="table" style="white-space: nowrap">
            <thead>
                <tr>
                    <th scope="col">Nr.</th>
                    <th scope="col">Name</th>
                    <th scope="col">LZ-Mon.</th>
                    <th scope="col">Schwelle</th>
                    <th scope="col">Limit</th>
                    <th scope="col">Funding</th>
                    <th scope="col">Funding-Start</th>
                    <th scope="col">Funding-Ende</th>
                    <th scope="col">Finanzierungsschluss</th>
                    <th scope="col">Funding-Tage</th>
                    <th scope="col">Investoren</th>
                    <th scope="col">Zuzählung</th>
                    <th scope="col">Zinsen/%</th>
                    <th scope="col">Zahlung/Zinsen</th>
                    <th scope="col">Zahlung/Kapital</th>
                    <th scope="col">Zwischenzahlung 1</th>
                    <th scope="col">Zwischenzahlung 2</th>
                    <th scope="col">Zwischenzahlung 3</th>
                    <th scope="col">Zwischenzahlung 4</th>
                    <th scope="col">Rückzahlung</th>
                    <th scope="col">Beratung</th>
                    <th scope="col">Erfolg</th>
                    <th scope="col">Admin</th>
                    <th scope="col">Zuzählungsbed.</th>
                    <th scope="col">Zinsen</th>
                    <th scope="col">Kapital</th>
                    <th scope="col">Rückzahlung</th>
                    <th scope="col">Geplante RZ</th>
                    <th scope="col">geplante Zinsen</th>
                    <th scope="col">Extra notes</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="project in projects" :key="project.id" :class="project.projectStatusClass">
                    <th scope="row">{{ project.v_5 }}</th>
                    <td>{{ project.bezeichnung }}</td>
                    <td>{{ project.i_4 }}</td>
                    <td>{{ project.i_1 | number }}</td>
                    <td>{{ project.i_5 | number }}</td>
                    <td>{{ project.invested | number }}</td>
                    <td>{{ project.d_9 | date }}</td>
                    <td>{{ project.d_1 | date }}</td>
                    <td>{{ twoWeeksLater(project.d_1) | date }}</td>
                    <td>{{ daysOfFunding(project.d_9, project.d_1) }}</td>
                    <td>{{ project.numberOfInvestments }}</td>
                    <td>{{ twoWeeksPlusOneDayLater(project.d_1) | date }}</td>
                    <td>{{ project.f_1 | number }} <span v-if="project.f_2">/ {{ project.f_2 | number }}</span></td>
                    <td>{{ project.v_1 }}</td>
                    <td>{{ project.v_1 }}</td>
                    <td><input placeholder="Zwischenzahlung 1" type="date" :value="project.interim_payment_1" :id="'interim_payment_1'+project.id" @change="submitInput(project.id, 'interim_payment_1')" @keydown.enter.prevent="submitInput(project.id, 'interim_payment_1')"></td>
                    <td><input placeholder="Zwischenzahlung 2" type="date" :value="project.interim_payment_2" :id="'interim_payment_2'+project.id" @change="submitInput(project.id, 'interim_payment_2')" @keydown.enter.prevent="submitInput(project.id, 'interim_payment_2')"></td>
                    <td><input placeholder="Zwischenzahlung 3" type="date" :value="project.interim_payment_3" :id="'interim_payment_3'+project.id" @change="submitInput(project.id, 'interim_payment_3')" @keydown.enter.prevent="submitInput(project.id, 'interim_payment_3')"></td>
                    <td><input placeholder="Zwischenzahlung 4" type="date" :value="project.interim_payment_4" :id="'interim_payment_4'+project.id" @change="submitInput(project.id, 'interim_payment_4')" @keydown.enter.prevent="submitInput(project.id, 'interim_payment_4')"></td>
                    <td><input placeholder="Rückzahlung" type="date" :value="project.payback" :id="'payback'+project.id" @change="submitInput(project.id, 'payback')" @keydown.enter.prevent="submitInput(project.id, 'payback')"></td>
                    <td><input placeholder="Beratung" :value="project.consultation | number" :id="'consultation'+project.id" @keydown.enter.prevent="submitInput(project.id, 'consultation')" @blur="submitInput(project.id, 'consultation')"></td>
                    <td><input placeholder="Erfolg" :value="project.success | number" :id="'success'+project.id" @keydown.enter.prevent="submitInput(project.id, 'success')" @blur="submitInput(project.id, 'success')"></td>
                    <td><input placeholder="Admin" :value="project.admin | number" :id="'admin'+project.id" @keydown.enter.prevent="submitInput(project.id, 'admin')" @blur="submitInput(project.id, 'admin')"></td>
                    <td><input placeholder="Zuzählungsbed." :value="project.payment_conditions" :id="'payment_conditions'+project.id" @keydown.enter.prevent="submitInput(project.id, 'payment_conditions')" @blur="submitInput(project.id, 'payment_conditions')"></td>
                    <td><input placeholder="Zinsen" :value="project.interest | number" :id="'interest'+project.id" @keydown.enter.prevent="submitInput(project.id, 'interest')" @blur="submitInput(project.id, 'interest')" @focus="convertToNumber(project.id, 'interest')"></td>
                    <td><input placeholder="Kapital" :value="project.capital | number" :id="'capital'+project.id" @keydown.enter.prevent="submitInput(project.id, 'capital')" @blur="submitInput(project.id, 'capital')" @focus="convertToNumber(project.id, 'capital')"></td>
                    <td><input placeholder="Rückzahlung" :value="project.paidback ? project.paidback : project.interest+project.capital | number" :id="'paidback'+project.id" @keydown.enter.prevent="submitInput(project.id, 'paidback')" @blur="submitInput(project.id, 'paidback')" @focus="convertToNumber(project.id, 'paidback')"></td>
                    <td><input placeholder="Geplante RZ" :value="project.planned_paidback | number" :id="'planned_paidback'+project.id" @keydown.enter.prevent="submitInput(project.id, 'planned_paidback')" @blur="submitInput(project.id, 'planned_paidback')" @focus="convertToNumber(project.id, 'planned_paidback')"></td>
                    <td><input placeholder="geplante Zinsen" :value="project.planned_interest | number" :id="'planned_interest'+project.id" @keydown.enter.prevent="submitInput(project.id, 'planned_interest')" @blur="submitInput(project.id, 'planned_interest')" @focus="convertToNumber(project.id, 'planned_interest')"></td>
                    <td><input placeholder="Extra notes" :value="project.extra_notes" :id="'extra_notes'+project.id" @keydown.enter.prevent="submitInput(project.id, 'extra_notes')" @blur="submitInput(project.id, 'extra_notes')"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import Xhr from '../../core/xhr';

    export default {
        props: {
        },

        created() {
            new Xhr().get('/dagoadmin/project-overview/get-projects').then((response) => {
                this.projects = response;
            })
        },

        filters: {
            number(value) {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 3 }).format((value));
            },

            date(value) {
                let dateFormat= new Date(Date.parse(value));

                return dateFormat.toLocaleDateString('de-DE', {});
            }
        },

        computed: {
            
        },

        methods: {
            twoWeeksLater(fromDate) {
                let d = new Date(Date.parse(fromDate));
                d.setDate(d.getDate() + 14);
                return d; 
            },

            twoWeeksPlusOneDayLater(fromDate) {
                let d = new Date(Date.parse(fromDate));
                d.setDate(d.getDate() + 14 + 1);
                return d;
            },

            daysOfFunding(startDate, endDate) {
                let date1 = new Date(Date.parse(startDate));
                let date2 = new Date(Date.parse(endDate));

                let diff = date2.getTime() - date1.getTime();
                let days = Math.round(diff / (1000 * 3600 * 24));

                return days;
            },

            convertToNumber(pid, field) {
                let fieldValue = document.getElementById(field+pid).value;
                fieldValue = fieldValue.replace(".", "");
                document.getElementById(field+pid).value = fieldValue;
            },

            submitInput(pid, field) {
                let fieldsWithNumbers = ["consultation", "success", "admin", "interest", "capital", "paidback", "planned_paidback", "planned_interest"];
                let fieldValue = document.getElementById(field+pid).value;
                let isNumber = false;
                if(fieldsWithNumbers.includes(field)) {
                    isNumber = true;
                    fieldValue = fieldValue.replace(",", ".");
                }
                if(field == 'interest' || field == 'capital') {
                    let interest = document.getElementById('interest'+pid).value;
                    interest = interest.replace(".", "");
                    interest = interest.replace(",", ".");
                    
                    let capital = document.getElementById('capital'+pid).value;
                    capital = capital.replace(".", "");
                    capital = capital.replace(",", ".");

                    document.getElementById('paidback'+pid).value = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 3 }).format((parseFloat(interest) + parseFloat(capital)));
                }

                new Xhr({pid: pid, field: field, fieldValue:fieldValue, isNumber:isNumber}).post('/dagoadmin/project-overview/submit-input').then((response) => {
                    if(isNumber) {
                        document.getElementById(field+pid).value = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 3 }).format((fieldValue));
                    }
                });
            }
        },

        data() {
            return {
                projects: [],
            };
        }
    }
</script>
<style>
    .containerHeight {
        height: calc(100vh - 300px);
    }
</style>