<template>
    <div class="investment-overlay__confirmation__box" v-if="showRiskWarning">
        <input type="hidden" name="investmentAcceptRiskWarningRequired" value="1">

        <div class="dg-input-wrapper dg-checkbox-input-wrapper" :class="{ 'has-error': error }">
            <input type="checkbox" name="investmentAcceptRiskWarning" id="investment-accept-risk-warning" value="1">
            <label for="investment-accept-risk-warning" v-if="userHasProvidedExperienceData" v-html="translate('investment_risk_warning')"></label>
            <label for="investment-accept-risk-warning" v-else v-html="translate('investment_risk_warning_no_experience_data')"></label>
        </div>

        <div class="dg-input-error has-icon" v-if="error">
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        initialShowRiskWarning: {
            type: Boolean,
            default: true
        },
        userHasProvidedExperienceData: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            showRiskWarning: false
        }
    },

    created () {
        this.showRiskWarning = this.initialShowRiskWarning

        App.dispatcher.listen('riskWarningDisplayChange', response => {
            this.showRiskWarning = response.showRiskWarning
        })
    },
}
</script>
