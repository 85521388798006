<template>
    <div class="login-content__block login-content__block--login">
        <TwoFactorChallange
            v-if="twoFactor"
            :two-factor-login-route="twoFactorLoginRoute"
            :two-factor-email-route="twoFactorEmailRoute"
            @loginSuccess="handleLoginSuccess">
        </TwoFactorChallange>
        <LoginForm
            v-else
            :is-app="isApp"
            :login-route="loginRoute"
            :header-input="headerInput"
            :ich-app-enabled="ichAppEnabled"
            :ich-app-login-route="ichAppLoginRoute"
            :reset-password-route="resetPasswordRoute"
            @loginSuccess="handleLoginSuccess">
        </LoginForm>
    </div>
</template>

<script>
import LoginForm from './auth/LoginForm.vue';
import TwoFactorChallange from './auth/TwoFactorChallange.vue';

export default {
    components: {
        LoginForm,
        TwoFactorChallange
    },
    props: {
        isApp: {
            type: Boolean,
            default: false
        },
        headerInput: {
            type: Boolean,
            default: false
        },
        loginRoute: {
            type: String,
            required: true
        },
        resetPasswordRoute: {
            type: String,
            required: true
        },
        twoFactorLoginRoute: {
            type: String,
            required: true
        },
        twoFactorEmailRoute: {
            type: String,
            required: true
        },
        ichAppLoginRoute: {
            type: String,
            required: true
        },
        ichAppEnabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            twoFactor: false
        }
    },
    methods: {
        handleLoginSuccess(response) {
            if (response.two_factor) {
                this.twoFactor = true;
            } else {
                window.location.reload();
            }
        },
    }
}
</script>
