import FinancialStructure from './FinancialStructure.vue'
import Milestones from './Milestones.vue'
import Portfolio from './Portfolio.vue'
import ClosedProjectsPortfolio from './ClosedProjectsPortfolio.vue'
import ClosedProjectsList from './ClosedProjectsList.vue'
import ProjectProgress from './ProjectProgress.vue'
import RangeSelector from './RangeSelector.vue'
import Spinner from './Spinner.vue'
import EnterIban from './EnterIban.vue'
import LastPayments from './LastPayments.vue'
import AllInvestments from './AllInvestments.vue'
import Performance from './Performance.vue'
import BuyInvestment from './BuyInvestment.vue'
import Messages from './Messages.vue'
import MessagesUnread from './MessagesUnread.vue'
import NewsletterRegistrationForm from './NewsletterRegistrationForm.vue'
import InvestmentInput from './InvestmentInput.vue'
import InvestmentRiskWarning from './InvestmentRiskWarning.vue'
import ServiceFee from './ServiceFee.vue'
import PayableAmount from './PayableAmount.vue'
import Glossary from './Glossary.vue'
import ShareCalculator from './ShareCalculator.vue'
import FormFiles from './FormFiles.vue'
import TwoFactorLogin from './TwoFactorLogin.vue'
import LanguageSelector from './LanguageSelector.vue'
import BlogGrid from './BlogGrid.vue'

import TransactionsTable from './dagoadmin/TransactionsTable.vue'
import ProjectOverviewTable from './dagoadmin/ProjectOverviewTable.vue'
import DagInvestmentFormSubmitsTable from './dagoadmin/DagInvestmentFormSubmitsTable.vue'

export default {
  FinancialStructure,
  Milestones,
  Portfolio,
  ClosedProjectsPortfolio,
  ClosedProjectsList,
  ProjectProgress,
  RangeSelector,
  Spinner,
  EnterIban,
  LastPayments,
  AllInvestments,
  Performance,
  BuyInvestment,
  Messages,
  MessagesUnread,
  NewsletterRegistrationForm,
  InvestmentInput,
  InvestmentRiskWarning,
  ServiceFee,
  PayableAmount,
  Glossary,
  FormFiles,
  TransactionsTable,
  TwoFactorLogin,
  LanguageSelector,
  BlogGrid,
  ProjectOverviewTable,
  DagInvestmentFormSubmitsTable
}
