export default class InView {

  constructor () {
    this.observers = [];
    this.initialized = false;
  }

  init() {
    return new Promise(async (resolve) => {
      if (!window.hasOwnProperty('IntersectionObserver')) {
        await import('intersection-observer' /* webpackChunkName: "js/modules/intersection-observer" */ )
      }
      this.initialized = true
      resolve()
    });
  }

  async observe (nodes, callback, event) {
    ! this.initialized && await this.init();

    nodes = typeof nodes === 'string'
      ? [].slice.call(document.querySelectorAll(nodes))
      : (nodes instanceof NodeList ? [].slice.call(nodes): [nodes]);

      if (nodes.length) {
        let observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              let el = entry.target

              observer.unobserve(el)

              event && window.App.dispatcher.fire(`inview.${event}`, { el })
              callback(el, observer)
            }
          })
        })

        this.observers.push(observer);
        for (let node of nodes) {
          observer.observe(node);
        }
      }
  }

  destroy () {
    for (let observer of this.observers) {
      observer.disconnect()
    }
  }
}
