<template>
  <div id="payable-amount" class="investment-overlay__submit__payable-amount">
    <span v-if="this.serviceFee > 0" v-html="translate('payable_amount_text', [{ key: 'PAYABLE_AMOUNT', value: $options.filters.money(payableAmount)}])"></span>
    <span v-else v-html="translate('payable_amount_text_no_fee', [{ key: 'PAYABLE_AMOUNT', value: $options.filters.money(payableAmount)}])"></span>
  </div>
</template>

<script>
export default {
  props: {
    initialInvestmentValue: {
      type: Number,
      required: false,
      default: 0
    },
    initialServiceFee: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data () {
    return {
      investmentValue: 0,
      zeroBondDiscount: 0,
      serviceFee: 0
    }
  },

  computed: {
    payableAmount () {
      return this.investmentValue - this.zeroBondDiscount + this.serviceFee
    }
  },

  created () {
    this.investmentValue = this.initialInvestmentValue
    this.serviceFee = this.initialServiceFee

    App.dispatcher.listen('investmentValueChange', response => {
      this.investmentValue = parseInt(response.investmentValue)
      this.zeroBondDiscount = parseFloat(response.zeroBondDiscount)
    })
    App.dispatcher.listen('serviceFeeChange', response => {
      this.serviceFee = parseFloat(response.serviceFee)
    })
  },

  filters: {
    money (value) {
      return new Intl.NumberFormat(
        'de-DE',
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      ).format(value)
    }
  }
}
</script>
