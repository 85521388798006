<template>
    <div>
        <input type="hidden" :name="name" :id="name" :value="this.values">
        <input type="hidden" name="numOfFiles" id="numOfFiles" :value="this.numfiles">
        <input type="file" id="file" ref="file" :accept="accept" @change="uploadFile()" v-if="!this.loading">
        <div v-if="this.loading">Loading...</div>
        <ul class="dg-input-file-list">
            <li class="dg-input-file-list-item" v-for="file in fileArray" :key="file.hash">
                <img class="file-icon" src="/images/icons/document.svg">
                <span class="file-name">{{ file['filename'] }}</span>
                <img class="file-delete" src="/images/icons/delete.svg" @click="deleteFile(file.hash)">
            </li>
        </ul>
    </div>
</template>
<script>
import Xhr from '../core/xhr';
import axios   from 'axios'

export default {
    props: {
        name: String,
        accept: String,
        old: String
    },
    created() {
        if(this.old != "") {
            this.values = this.old;
            let valuesArray = this.values.split(',');
            for(let i = 0; i < valuesArray.length; i++) {
                new Xhr({hash: valuesArray[i]}).post('/formFiles/getFilename').then((response) => {
                    this.fileArray.push(response);
                })
                this.numfiles++;
            }
        }
    },

    methods: {
        uploadFile() {
            this.file = this.$refs.file.files[0];
            let fileExtention = "." + this.file['name'].split('.').pop();
            let fileSize = this.file['size'];
            let extentionsArray = this.accept.split(',');

            console.log(fileSize);
            
            if(extentionsArray.includes(fileExtention) && fileSize <= this.maxFileSize) {
                this.loading = true;
                let formData = new FormData();
                formData.append('file', this.file);
                var self = this;

                axios.post( '/formFiles/upload', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(function(response) {
                    if(self.values == '') {
                        self.values = response['data']['hash'];
                    } else {
                        self.values = self.values + "," + response['data']['hash'];
                    }
                    self.fileArray.push(response['data']);
                    self.numfiles++;
                })
                .catch(function(){
                    console.log('fail');
                })
                .finally(() => {
                    this.loading = false;
                });
            } else {
                if(fileSize > this.maxFileSize) {
                    alert('File too big. Max size: ' + this.maxFileSize / 1000000 + 'MB');
                } else {
                    alert('File type not allowed. Please use: ' + this.accept);
                }
            }
        },

        deleteFile(hash) {
            var newArray = [];
            this.values = '';
            this.numfiles = 0;
            for(let i = 0; i < this.fileArray.length; i++) {
                if(this.fileArray[i]['hash'] != hash) {
                    newArray.push(this.fileArray[i]);
                    if(this.values == '') {
                        this.values = this.fileArray[i]['hash'];
                    } else {
                        this.values = this.values + "," + this.fileArray[i]['hash'];
                    }
                    this.numfiles++;
                } else {
                    new Xhr({hash: hash}).post('/formFiles/delete');
                }
            }

            this.fileArray = newArray;
        }
    },

    data () {
        return {
            loading: false,
            values: "",
            file: "",
            fileArray: [],
            maxFileSize: 3000000,
            numfiles: 0
        }
    }
}
</script>
