<template>
    <span v-if="unread != 0">{{unread | checkSize}}</span>
</template>
<script>
    export default {
        props: {
            start: Number
        },

        created() {
            this.unread = this.start;

            App.dispatcher.listen('numberOfUnreadMessagesChange', (response) => {
                this.unread = response.numberOfunead;
            })
        },

        filters: {
            checkSize(value) {
                if(value > 9) {
                    return '9+';
                } else {
                    return value;
                }
            }
        },

        data() {
            return {
                unread: 0
            };
        }
    }
</script>