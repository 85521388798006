<template>
    <div class="row">
        <div class="col-12">
            <div class="bg-white">
                <div class="title">{{ translate('paid_out_until_now') }}</div>
                <div v-if="values.length > 0">
                    <table class="d-none d-sm-table">
                        <thead>
                            <tr>
                                <td>{{ translate('projects') }}</td>
                                <td>{{ translate('payment') }}</td>
                                <td>{{ translate('invested_lowercase') }}</td>
                                <td>{{ translate('type') }}</td>
                                <td>{{ translate('paid_out') }}</td>
                                <td>{{ translate('received') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="value in payments" :key="value.id">
                                <td v-if="value.nice_url"><a :href='"/"+value.nice_url'>{{ value.bezeichnung }}</a></td>
                                <td v-else>{{ value.bezeichnung }}</td>
                                <td>{{ value.einzahlung }}</td>
                                <td><span class="ntl-monetary">{{ value.investiert }}€</span></td>
                                <td>{{ value.artString }}</td>
                                <td><span class="ntl-monetary">{{ value.ausbezahlt }}€</span></td>
                                <td>{{ value.erhalten }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-for="value in payments" :key="value.id">
                        <table class="d-sm-none mobileTable">
                            <tr>
                                <td colspan="3"><h5>{{ value.bezeichnung }}</h5></td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <b>{{ translate('payment') }}</b><br>
                                    {{ value.einzahlung }}
                                </td>
                                <td>
                                    <b>{{ translate('invested_lowercase') }}</b><br>
                                    <span class="ntl-monetary">{{ value.investiert }}€</span>
                                </td>
                            </tr>
                        </table>
                        <table class="d-sm-none mobileTable">
                            <tr>
                                <td class="text-center">
                                    <b>{{ translate('type') }}</b><br>
                                    {{ value.artString }}
                                </td>
                                <td>
                                    <b>{{ translate('paid_out') }}</b><br>
                                    <span class="ntl-monetary">{{ value.ausbezahlt }}€</span>
                                </td>
                                <td>
                                    <b>{{ translate('received') }}</b><br>
                                    {{ value.erhalten }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="row" v-if="showBtn">
                        <div class="col-12 text-center mt-5">
                            <button type="button" class="btn btn-secondary" @click="geteNext25">{{ translate('More') }}</button>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center" v-else>
                    <div class="col-12 mt-3 mb-3 pt-2 pb-2 col-sm-10 col-lg-6 mt-sm-5 mb-sm-5 pt-sm-4 pb-sm-4 noData">
                        {{ translate('no_paybacks_yet') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Xhr from '../core/xhr';

    export default {
        props: {
            values: Array,
        },

        created() {
            this.payments = this.values;
        },

        methods: {
            geteNext25 () {
                new Xhr({start: this.startPos}).post('/backend/dashboard/requestNext25LastPaymnets').then((response) => {
                    this.startPos = parseInt(this.startPos) + parseInt(response.items.length);
                    for(let i=0; i< response.items.length; i++) {
                        this.payments.push(response.items[i]);
                    }
                    if(parseInt(response.items.length) < 25) {
                        this.showBtn = false;
                    }
                })
            },
        },

        data() {
            return {
                payments: [],
                startPos: 5,
                showBtn: true
            };
        }
    }
</script>
