<template>
    <div class="container">
        <div class="row mb-4">
            <div class="col-xl-10 offset-xl-1">
                <div class="bg-white">
                    <div class="row">
                        <div class="d-none d-md-block col-3">
                            <div id="leftCol">
                                <div class="title d-none d-md-block">{{ translate('categories') }}</div>
                                <a href="#" :class="[(this.category == '') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages();">{{ translate('all') }} <b v-if="numberOfUnread() > 0">({{numberOfUnread()}})</b></a>
                                <a href="#" :class="[(this.category == 'Start') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Start');">{{ translate('project_starts') }} <b v-if="numberOfUnreadOfCategory('Start') > 0">({{numberOfUnreadOfCategory('Start')}})</b></a>
                                <a href="#submenu0" data-toggle="collapse" role="button" :class="['multi', 'collapsed', (this.category == 'Backpay') ? 'selected' : '']">{{ translate('payouts') }} <b v-if="numberOfUnreadOfCategory('Backpay') > 0">({{numberOfUnreadOfCategory('Backpay')}})</b></a>
                                <div class="collapse submenu" id="submenu0">
                                    <a href="#" :class="[(this.category == 'Backpay') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Backpay');">{{ translate('all') }} <b v-if="numberOfUnreadOfCategory('Backpay') > 0">({{numberOfUnreadOfCategory('Backpay')}})</b></a>
                                    <a href="#" :class="[(this.subcategory == 'full') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Backpay', 'full');">{{ translate('payback') }} <b v-if="numberOfUnreadOfCategory('full') > 0">({{numberOfUnreadOfCategory('full')}})</b></a>
                                    <a href="#" :class="[(this.subcategory == 'interest') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Backpay', 'interest');">{{ translate('interest_pay') }} <b v-if="numberOfUnreadOfCategory('interest') > 0">({{numberOfUnreadOfCategory('interest')}})</b></a>
                                    <a href="#" :class="[(this.subcategory == 'partial') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Backpay', 'partial');">{{ translate('partial_payback') }} <b v-if="numberOfUnreadOfCategory('partial') > 0">({{numberOfUnreadOfCategory('partial')}})</b></a>
                                </div>
                                <a href="#submenu2" data-toggle="collapse" role="button" :class="['multi', 'collapsed', (this.category == 'Info') ? 'selected' : '']">{{ translate('dagobert_news') }} <b v-if="numberOfUnreadOfCategory('Info') > 0">({{numberOfUnreadOfCategory('Info')}})</b></a>
                                <div class="collapse submenu" id="submenu2">
                                    <a href="#" :class="[(this.category == 'Info') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Info');">{{ translate('all') }} <b v-if="numberOfUnreadOfCategory('Info') > 0">({{numberOfUnreadOfCategory('Info')}})</b></a>
                                    <a href="#" :class="[(this.subcategory == 'weekend') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Info', 'weekend');">{{ translate('content') }} <b v-if="numberOfUnreadOfCategory('weekend') > 0">({{numberOfUnreadOfCategory('weekend')}})</b></a>
                                    <a href="#" :class="[(this.subcategory == 'aktion') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Info', 'aktion');">{{ translate('actions') }} <b v-if="numberOfUnreadOfCategory('aktion') > 0">({{numberOfUnreadOfCategory('aktion')}})</b></a>
                                </div>
                                <a href="#submenu3" data-toggle="collapse" role="button" :class="['multi', 'collapsed', (this.category == 'ProjectNews') ? 'selected' : '']">{{ translate('project_news') }} <b v-if="numberOfUnreadOfCategory('ProjectNews') > 0">({{numberOfUnreadOfCategory('ProjectNews')}})</b></a>
                                <div class="collapse submenu" id="submenu3">
                                    <a href="#" :class="[(this.category == 'ProjectNews') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('ProjectNews');">{{ translate('all') }} <b v-if="numberOfUnreadOfCategory('ProjectNews') > 0">({{numberOfUnreadOfCategory('ProjectNews')}})</b></a>
                                    <a href="#" :class="[(subcategory == `${item}News`) ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('ProjectNews', `${item}News`)" v-for="(item, index) in projectCategories" :key="index">
                                        {{ item }} <b v-if="numberOfUnreadOfCategory(`${item}News`) > 0">({{numberOfUnreadOfCategory(`${item}News`)}})</b>
                                    </a>
                                </div>
                                <!--<a href="#">Kundenservice</a>-->
                                <!--<a href="#">Zahlungsbestätigungen</a>-->
                                <a href="#" v-if="this.platinum == '1'" :class="[(this.category == 'Platinum') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Platinum');">{{ translate('platinum') }} <b v-if="numberOfUnreadOfCategory('Platinum') > 0">({{numberOfUnreadOfCategory('Platinum')}})</b></a>
                                <a href="#" v-if="numberOfMessagesOfCategory('Other') > 0" :class="[(this.category == 'Other') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Other');">{{ translate('others') }} <b v-if="numberOfUnreadOfCategory('Other') > 0">({{numberOfUnreadOfCategory('Other')}})</b></a>
                            </div>
                        </div>
                        <div class="col-12 col-md-9">
                            <div id="rightCol">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="title d-none d-md-block">{{ translate('messages') }}</div>
                                        <div class="categories d-md-none" id="showCategories">{{ translate('all_categories') }}</div>
                                        <div id="leftCol" class="leftColCategories">
                                            <div class="title" id="hideCategories">{{ translate('categories') }}</div>
                                            <a href="#" :class="[(this.category == '') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages();">{{ translate('all') }} <b v-if="numberOfUnread() > 0">({{numberOfUnread()}})</b></a>
                                            <a href="#" :class="[(this.category == 'Start') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Start');">{{ translate('project_starts') }} <b v-if="numberOfUnreadOfCategory('Start') > 0">({{numberOfUnreadOfCategory('Start')}})</b></a>
                                            <a href="#submenu0" data-toggle="collapse" role="button" :class="['multi', 'collapsed', (this.category == 'Backpay') ? 'selected' : '']">{{ translate('payouts') }} <b v-if="numberOfUnreadOfCategory('Backpay') > 0">({{numberOfUnreadOfCategory('Backpay')}})</b></a>
                                            <div class="collapse submenu" id="submenu0">
                                                <a href="#" :class="[(this.category == 'Backpay') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Backpay');">{{ translate('all') }} <b v-if="numberOfUnreadOfCategory('Backpay') > 0">({{numberOfUnreadOfCategory('Backpay')}})</b></a>
                                                <a href="#" :class="[(this.subcategory == 'full') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Backpay', 'full');">{{ translate('payback') }} <b v-if="numberOfUnreadOfCategory('full') > 0">({{numberOfUnreadOfCategory('full')}})</b></a>
                                                <a href="#" :class="[(this.subcategory == 'interest') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Backpay', 'interest');">{{ translate('interest_pay') }} <b v-if="numberOfUnreadOfCategory('interest') > 0">({{numberOfUnreadOfCategory('interest')}})</b></a>
                                                <a href="#" :class="[(this.subcategory == 'partial') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Backpay', 'partial');">{{ translate('partial_payback') }} <b v-if="numberOfUnreadOfCategory('partial') > 0">({{numberOfUnreadOfCategory('partial')}})</b></a>
                                            </div>
                                            <a href="#submenu2" data-toggle="collapse" role="button" :class="['multi', 'collapsed', (this.category == 'Info') ? 'selected' : '']">{{ translate('dagobert_news') }} <b v-if="numberOfUnreadOfCategory('Info') > 0">({{numberOfUnreadOfCategory('Info')}})</b></a>
                                            <div class="collapse submenu" id="submenu2">
                                                <a href="#" :class="[(this.category == 'Info') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Info');">{{ translate('all') }} <b v-if="numberOfUnreadOfCategory('Info') > 0">({{numberOfUnreadOfCategory('Info')}})</b></a>
                                                <a href="#" :class="[(this.subcategory == 'weekend') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Info', 'weekend');">{{ translate('content') }} <b v-if="numberOfUnreadOfCategory('weekend') > 0">({{numberOfUnreadOfCategory('weekend')}})</b></a>
                                                <a href="#" :class="[(this.subcategory == 'aktion') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Info', 'aktion');">{{ translate('actions') }} <b v-if="numberOfUnreadOfCategory('aktion') > 0">({{numberOfUnreadOfCategory('aktion')}})</b></a>
                                            </div>
                                            <a href="#submenu3" data-toggle="collapse" role="button" :class="['multi', 'collapsed', (this.category == 'ProjectNews') ? 'selected' : '']">{{ translate('project_news') }} <b v-if="numberOfUnreadOfCategory('ProjectNews') > 0">({{numberOfUnreadOfCategory('ProjectNews')}})</b></a>
                                            <div class="collapse submenu" id="submenu3">
                                                <a href="#" :class="[(this.category == 'ProjectNews') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('ProjectNews');">{{ translate('all') }} <b v-if="numberOfUnreadOfCategory('ProjectNews') > 0">({{numberOfUnreadOfCategory('ProjectNews')}})</b></a>
                                                <a href="#" :class="[(subcategory == `${item}News`) ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('ProjectNews', `${item}News`);" v-for="(item, index) in projectCategories" :key="index">
                                                    {{ item }} <b v-if="numberOfUnreadOfCategory(`${item}News`) > 0">({{numberOfUnreadOfCategory(`${item}News`)}})</b>
                                                </a>
                                            </div>
                                            <!--<a href="#">Kundenservice</a>-->
                                            <!--<a href="#">Zahlungsbestätigungen</a>-->
                                            <a href="#" v-if="this.platinum == '1'" :class="[(this.category == 'Platinum') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Platinum');">{{ translate('platinum') }} <b v-if="numberOfUnreadOfCategory('Platinum') > 0">({{numberOfUnreadOfCategory('Platinum')}})</b></a>
                                            <a href="#" v-if="numberOfMessagesOfCategory('Other') > 0" :class="[(this.category == 'Other') ? 'selected' : '']" @click.stop.prevent="getFilteredMessages('Other');">{{ translate('others') }} <b v-if="numberOfUnreadOfCategory('Other') > 0">({{numberOfUnreadOfCategory('Other')}})</b></a>
                                        </div>
                                    </div>
                                    <div class="d-none d-sm-block col-6 text-right">
                                        <div class="title">{{ translate('date') }}</div>
                                    </div>
                                </div>
                                <div class="modal fade" id="newsletterModal" tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                {{ title }}
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="icon-close"></i></button>
                                            </div>
                                            <div class="modal-body">
                                                <iframe v-if="type === 'mailchimp'" :src="mailChimpLink" title="title"></iframe>
                                                <iframe v-else :srcdoc="body" title="title"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6"><input type="checkbox" v-model="selectAll" @change="selectAllMessages()"> {{ translate('select_everything') }}</div>
                                    <div class="col-6 text-right"><button class="btn btn-primary small" @click="markAsRead()">{{ translate('mark_selected_as_read') }}</button></div>
                                </div>
                                <div v-for="(month, key, index) in filteredValues" :key="key">
                                    <div class="month">
                                        <div  class="monthCheck">
                                            <input type="checkbox" :value="key" v-model="monthsToMark" @change="monthSelect(key)">
                                        </div>
                                        <span :class="{ collapsed: index > 0 }" data-toggle="collapse" :href="'#collapse'+key" role="button" aria-expanded="false">{{key.substr(5) | monthtext(translate)}} {{key.substr(0, 4)}}</span>
                                        <b v-if="numberOfUnreadOfMonth(key) > 0">({{numberOfUnreadOfMonth(key)}})</b>
                                    </div>
                                    <div class="collapse" :class="{ show: index == 0 }" :id="'collapse'+key">
                                        <table>
                                            <tr v-for="(message, index) in month" :key="index">
                                                <td>
                                                    <input type="checkbox" :value="message.markId" v-model="messagesToMark" @change="checkMonthSelect(key)">
                                                </td>
                                                <td>
                                                    <span class="messageOpenLink" data-toggle="modal" data-target="#newsletterModal" @click="getContent(message, key, index)" v-if="message.messageType === 'mail'">
                                                        <b v-if="message.open == 0">{{ message.betreff }}</b>
                                                        <span v-else>{{ message.betreff }}</span>
                                                    </span>
                                                    <span class="messageOpenLink" data-toggle="modal" data-target="#newsletterModal" @click="getMailchimpContent(message, key, index)" v-else-if="message.messageType === 'mailchimp'">
                                                        <b v-if="message.open == 0">{{ message.betreff }}</b>
                                                        <span v-else>{{ message.betreff }}</span>
                                                    </span>
                                                    <span class="messageOpenLink" @click="openArticle(message, key, index)" v-else-if="message.messageType === 'article'">
                                                        <b v-if="message.open == 0">{{ message.betreff }}</b>
                                                        <span v-else>{{ message.betreff }}</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <b v-if="message.open == 0">{{ parseInt(message.send.substr(8,2)) }}. {{ message.send.substr(5,2) | monthtext(translate) }}</b>
                                                    <span v-else>{{ parseInt(message.send.substr(8,2)) }}. {{ message.send.substr(5,2) | monthtext(translate) }}</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Xhr from '../core/xhr';

    export default {
        props: {
            platinum: String,
            projectCategories: Array
        },

        created() {
            new Xhr().post('/backend/nachrichten/getList').then((response) => {
                this.values = response;
                this.filteredValues = response;
            })
        },

        methods: {
            getFilteredMessages(category = null, subcategory= null) {
                this.filteredValues = [];
                this.messagesToMark = [];
                this.monthsToMark = [];
                this.selectAll = false;

                if(category === null) {
                    new Xhr().post('/backend/nachrichten/getList').then((response) => {
                        this.filteredValues = response;
                        this.category = '';
                        this.subcategory = '';
                    })
                } else {
                    if(subcategory === null) {
                        new Xhr({category: category}).post('/backend/nachrichten/getList').then((response) => {
                            this.filteredValues = response;
                            this.category = category;
                            this.subcategory = '';
                        })
                    } else {
                        new Xhr({category: category, subcategory: subcategory}).post('/backend/nachrichten/getList').then((response) => {
                            this.filteredValues = response;
                            this.category = category;
                            this.subcategory = subcategory;
                        })
                    }
                }

                $('.leftColCategories').hide('show');
            },

            numberOfMessagesOfCategory(category) {
                var numberOfMessages = 0;
                for(var item in this.values) {
                    for(let i = 0; i < this.values[item].length; i++) {
                        if(this.values[item][i].category == category) {
                            numberOfMessages++;
                        }
                    }
                }
                return numberOfMessages;
            },

            numberOfUnreadOfMonth(key) {
                var numberOfunead = 0;

                for(let i = 0; i < this.filteredValues[key].length; i++) {
                    if(this.filteredValues[key][i].open == 0) {
                        numberOfunead++;
                    }
                }
                return numberOfunead;
            },

            numberOfUnreadOfCategory(category) {
                var numberOfunead = 0;
                for(var item in this.values) {
                    for(let i = 0; i < this.values[item].length; i++) {
                        if(this.values[item][i].open == 0 && (this.values[item][i].category == category || this.values[item][i].subcategory == category)) {
                            numberOfunead++;
                        }
                    }
                }
                return numberOfunead;
            },

            numberOfUnread() {
                var numberOfunead = 0;
                for(var item in this.values) {
                    for(let i = 0; i < this.values[item].length; i++) {
                        if(this.values[item][i].open == 0) {
                            numberOfunead++;
                        }
                    }
                }

                return numberOfunead;
            },

            selectAllMessages() {
                if(this.selectAll === true) {
                    for(var item in this.filteredValues) {
                        for(let i = 0; i < this.filteredValues[item].length; i++) {
                            if(!this.messagesToMark.includes(this.filteredValues[item][i].markId)) {
                                this.messagesToMark.push(this.filteredValues[item][i].markId);
                            }
                        }
                        if(!this.monthsToMark.includes(item)) {
                            this.monthsToMark.push(item);
                        }
                    }
                } else {
                    this.monthsToMark = [];
                    this.messagesToMark = [];
                }
            },

            checkSelectAll() {
                var missing = false;
                for(var item in this.filteredValues) {
                    if(!this.monthsToMark.includes(item)) {
                        missing = true;
                        break;
                    }
                    for(let i = 0; i < this.filteredValues[item].length; i++) {
                        if(!this.messagesToMark.includes(this.filteredValues[item][i].markId)) {
                            missing = true;
                            break;
                        }
                    }
                }

                if(missing) {
                    this.selectAll = false;
                } else {
                    this.selectAll = true;
                }
            },

            checkMonthSelect(key) {
                var missing = false;

                for(let i = 0; i < this.filteredValues[key].length; i++) {
                    if(!this.messagesToMark.includes(this.filteredValues[key][i].markId)) {
                        missing = true;
                    }
                }

                if(missing) {
                    if(this.monthsToMark.includes(key)) {
                        this.monthsToMark = this.monthsToMark.filter(function(ele){
                            return ele != key;
                        });
                    }
                } else {
                    if(!this.monthsToMark.includes(key)) {
                        this.monthsToMark.push(key);
                    }
                }

                this.checkSelectAll();
            },

            monthSelect(key) {
                if(this.monthsToMark.includes(key)) {
                    for(let i = 0; i < this.filteredValues[key].length; i++) {
                        if(!this.messagesToMark.includes(this.filteredValues[key][i].markId)) {
                            this.messagesToMark.push(this.filteredValues[key][i].markId);
                        }
                    }
                } else {
                    for(let i = 0; i < this.filteredValues[key].length; i++) {
                        let value = this.filteredValues[key][i].markId;
                        this.messagesToMark = this.messagesToMark.filter(function(ele){
                            return ele != value;
                        });
                    }
                }

                this.checkSelectAll();
            },

            getContent(message, key, index) {
                new Xhr({id: message.id}).post('/backend/nachrichten/getContent').then((response) => {
                    this.type = 'mail';
                    this.title = response.title;
                    this.body = response.body;
                    this.mailChimpLink = null;
                })

                this.filteredValues[key][index].open = 1;
                for(let i = 0; i < this.values[key].length; i++) {
                    if(this.filteredValues[key][index].betreff == this.values[key][i].betreff) {
                        this.values[key][i].open = 1;
                        break;
                    }
                }

                if(!$('#newsletterModal').hasClass("show")) {
                    $('#newsletterModal').modal('show');
                }

                window.App.dispatcher.fire('numberOfUnreadMessagesChange', { numberOfunead: this.numberOfUnread() })
            },

            async getMailchimpContent(message, key, index) {

                this.mailChimpLink = message.url;
                this.title = message.betreff;
                this.type = 'mailchimp';
                this.body = null;

                if (!$('#newsletterModal').hasClass("show")) {
                    $('#newsletterModal').modal('show');
                }

                if (message.open === 0) {
                    await new Xhr({messages: [message.markId]}).post('/backend/nachrichten/markAsRead');
                    this.filteredValues[key][index].open = 1;
                    for(let i = 0; i < this.values[key].length; i++) {
                        if(this.filteredValues[key][index].betreff == this.values[key][i].betreff) {
                            this.values[key][i].open = 1;
                            break;
                        }
                    }
                }
            },

            openArticle(message, key, index) {
                this.filteredValues[key][index].open = 1
                for (let i = 0; i < this.values[key].length; i++) {
                    if (this.filteredValues[key][index].betreff === this.values[key][i].betreff) {
                        this.values[key][i].open = 1
                        break
                    }
                }

                window.App.dispatcher.fire(
                    'numberOfUnreadMessagesChange',
                    { numberOfunead: this.numberOfUnread() }
                )

                // We don't have to manually mark the article as read in the
                // database via XHR here because opening it (in a separate tab)
                // does that anyway
                window.open(message.url, '_blank').focus()
            },

            markAsRead() {
                for(var item in this.values) {
                    for(let i = 0; i < this.values[item].length; i++) {
                        if(this.messagesToMark.includes(this.values[item][i].markId)) {
                            this.values[item][i].open = 1;
                        }
                    }
                }

                window.App.dispatcher.fire('numberOfUnreadMessagesChange', { numberOfunead: this.numberOfUnread() })

                new Xhr({messages: this.messagesToMark}).post('/backend/nachrichten/markAsRead');

                for(var item in this.filteredValues) {
                    for(let i = 0; i < this.filteredValues[item].length; i++) {
                        if(this.messagesToMark.includes(this.filteredValues[item][i].markId)) {
                            this.filteredValues[item][i].open = 1;
                        }
                    }
                }

                this.selectAll = false;
                this.messagesToMark = [];
                this.monthsToMark = [];
            }
        },

        filters: {
            // FIXME: Filters should not have access to context, so we can't
            // access the global this.translate() here.
            // Passing in the translate() function is an ugly hack to avoid
            // refactoring.
            // See https://github.com/vuejs/vue/issues/5998#issuecomment-421666086
            monthtext(value, translate) {
                let number = parseInt(value);

                switch(number) {
                    case 1:
                        return translate('month_january');
                    case 2:
                        return translate('month_february');
                    case 3:
                        return translate('month_march');
                    case 4:
                        return translate('month_april');
                    case 5:
                        return translate('month_may');
                    case 6:
                        return translate('month_june');
                    case 7:
                        return translate('month_july');
                    case 8:
                        return translate('month_august');
                    case 9:
                        return translate('month_september');
                    case 10:
                        return translate('month_october');
                    case 11:
                        return translate('month_november');
                    case 12:
                        return translate('month_december');
                }
            }
        },

        data() {
            return {
                title: '',
                body: '',
                type: null,
                mailChimpLink: null,
                messagesToMark: [],
                monthsToMark: [],
                selectAll: false,
                values: {},
                filteredValues: {},
                category: '',
                subcategory: ''
            };
        }
    }
</script>
